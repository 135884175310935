// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-digitointi-js": () => import("./../../../src/pages/digitointi.js" /* webpackChunkName: "component---src-pages-digitointi-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tiedostojen-palautus-js": () => import("./../../../src/pages/tiedostojen-palautus.js" /* webpackChunkName: "component---src-pages-tiedostojen-palautus-js" */),
  "component---src-pages-vuokraus-js": () => import("./../../../src/pages/vuokraus.js" /* webpackChunkName: "component---src-pages-vuokraus-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

